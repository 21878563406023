import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Component, OnInit, HostListener } from '@angular/core';

export abstract class ContactsBarComponent implements OnInit{
  public contactsBarState: string;

  constructor( router: Router, 
               sanitizer: DomSanitizer ){
    this.contactsBarState = 'hidden';
  }

  abstract ngOnInit();

	@HostListener( 'window:scroll', ['$event'] )
	onScroll( event ){
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    this.contactsBarState = verticalOffset <= 100 && this.contactsBarState !== 'show' ? 'hidden' : 'show';
  }
}
