export class CarousselControl{
  public index: number;

  //Se true, significa que esse item do carrossel esta sendo vsualizado no momento.
  public isViewed: boolean;

  public imageSrc: string;
  public urlSlide: string; //URL para o usuário ver mais detalhes da noticia/post/artigo.
  public txDescricaoSlide: string;

  public state: string; //hidden, show, slipLeft, slipRight

  public offsetOfSlide: number; //Deslocamento do slide. Exemplo: 100.
  public offsetStrOfSlide: string; //Deslocamento do slide. Exemplo: 100vw.
  public timeStrOfAnimation: string; //Tempo da animacao. Exemplo: 1s.

  public constructor( index: number, imageSrc: string ){
    this.index    = index;
    this.isViewed = false;
    this.imageSrc  = imageSrc;
    this.offsetOfSlide = 100;
    this.offsetStrOfSlide = '+100vw';
    this.timeStrOfAnimation = '1s';
  }
}

export class CarousselControlUsina extends CarousselControl{
  public cidade: string;
  public uf: string;
  public potenciaPico: number;
  public energiaMensalGeradaMedia: number;

  public constructor( index: number, imageSrc: string, cidade: string, uf: string, potenciaPico: number, energiaMensalGeradaMedia: number ){
    super( index, imageSrc );

    this.cidade = cidade;
    this.uf = uf;
    this.potenciaPico = potenciaPico;
    this.energiaMensalGeradaMedia = energiaMensalGeradaMedia;
  }
}
