import { Component                      } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { HomeComponent                  } from '../home.component';
import { NoticiasService } from '../../_services/noticias/noticias.service';
import { AplicationStateService } from '../../_services/aplication-state/aplication.state.service';
import { PostsService } from 'src/app/_services/posts/posts.service';



@Component({
	selector: 'app-home-mobile',
	templateUrl: '../desktop/home.desktop.component.html',
	styleUrls: ['./home.mobile.component.css'],

	animations: [
		trigger( 'moveCaroussel', [
			state( 'slipToLeft', 
				style( { transform: 'translateX( {{offsetSlide}} )' } ), { params: { offsetSlide: '-100vw' } }
			),

			state( 'slipToRight', 
				style( { transform: 'translateX( {{offsetSlide}} )' } ), { params: { offsetSlide: '100vw' } }
			),

			state( 'show', 
				style( { transform: 'translateX( 0vw )' } )
			),

			transition( '* => *', [
				animate( '{{timeAnimate}}' )
			]),
		]),
	]
})
export class HomeMobileComponent extends HomeComponent {
  constructor( router: Router,
               sanitizer: DomSanitizer,
			   aplicationStateService: AplicationStateService,
			   protected noticiasService        : NoticiasService,
			   protected postsService: PostsService ) {
    super( router, sanitizer, aplicationStateService, noticiasService, postsService );
  }
}
