import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

/* Import de services */
import { CidadesService } from '../../../_services/cidades/cidades.service';
import { UfService      } from '../../../_services/uf/uf.service';


export interface UF{
  value: string;
  viewValue: string;
}

export interface Cidade{
  uf: string;
  nome: string;
}


export class ResultadoOrcPrelComponentModel {
  private cidadesService;
  private ufService;

  public imageUrl: SafeResourceUrl;

  private ufList: UF[] = null;
  private cidadeList: Cidade[] = [];

  public nome: string;
  public telefone: string;
  public email: string;
  public uf: string;
  public nmCidade: string;
  public demandaMedMensal: number;
  public custoMin: number;
  public custoMax: number;
  public custoMed: number;
  public potPicoInvMin: number;
  public potPicoInvMax: number;

  constructor( private sanitizer: DomSanitizer, private cidadesServiceLocal: CidadesService, private ufServiceLocal: UfService ) {
    this.cidadesService = cidadesServiceLocal;
    this.ufService = ufServiceLocal;

    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl( '' );
  }

  public setNome( nome: string ){
    this.nome = nome;
  }

  public setTelefone( telefone: string ){
    this.telefone = telefone;
  }

  public setEmail( email: string ){
    this.email = email;
  }

  public setUf( uf: string ){
    this.uf = uf;
  }

  public setNmCidade( nmCidade: string ){
    this.nmCidade = nmCidade;
  }

  public setDemandaMedMensal( demandaMedMensal: number ){
    this.demandaMedMensal = demandaMedMensal;
  }

  public setCustoMin( custoMin: number ){
    this.custoMin = custoMin;
  }

  public setCustoMax( custoMax: number ){
    this.custoMax = custoMax;
  }

  public setCustoMed( custoMed: number ){
    this.custoMed = custoMed;
  }

  public setPotPicoInvMin( value: number ){
    this.potPicoInvMin = value;
  }

  public setPotPicoInvMax( value: number ){
    this.potPicoInvMax = value;
  }
}
