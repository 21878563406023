import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { ContactsBarComponent } from '../contacts-bar.component';
import { trigger, state, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-contacts-bar-mobile',
  templateUrl: '../desktop/contacts-bar.desktop.component.html',
  styleUrls: ['./contacts-bar.mobile.component.css'],

	animations: [
		trigger( 'contacts-bar', [
			state( 'hidden', 
				style( { transform: 'translateX( -200vw )' } )
			),

			state( 'show', 
				style( { transform: 'translateX( 0vw )' } )
			),

			transition( 'hidden => show', [
				animate( '1s' )
			]),
		]),
	]
})
export class ContactsBarMobileComponent extends ContactsBarComponent {

  constructor( router: Router, 
               sanitizer: DomSanitizer ){
    super( router, sanitizer );
  }

  ngOnInit() {
    console.log( 'Component \'app-contacts-bar-mobile\'...' );
  }
}
