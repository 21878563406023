import { Component, OnInit      } from '@angular/core';
import { Router                 } from '@angular/router';
import { DomSanitizer           } from '@angular/platform-browser';
import { HeaderComponentModel   } from '../header.component.model';
import { MatToolbarModule       } from '@angular/material/toolbar';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header.desktop.component.html',
  styleUrls: ['./header.desktop.component.css']
})
export class HeaderDesktopComponent implements OnInit {
  private model: HeaderComponentModel;

  constructor( private router: Router, 
               private sanitizer: DomSanitizer ){
    this.model = new HeaderComponentModel( sanitizer );
  }

  ngOnInit(){
    console.log( "Component 'app-header-desktop' iniciado." );
  }
}
