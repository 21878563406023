import { Component, OnInit                      } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { QuemSomosComponentModel } from './quem.somos.component.model';

export abstract class QuemSomosComponent implements OnInit{
  private model: QuemSomosComponentModel;

  constructor( router: Router, 
               sanitizer: DomSanitizer ){
    this.model = new QuemSomosComponentModel( sanitizer );
  }

  ngOnInit() {
    console.log( 'Component \'quem-somos\' inciado...' );
  }
}
