export class AppComponentModel {
  public title: string;

  constructor(){
    console.log( "Construtor 'AppComponentModel()'..." );

    this.title = 'Ansolar';

    console.log( "Construtor 'AppComponentModel()'." );
  }
}
