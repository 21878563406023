import { Component, OnInit                  } from '@angular/core';
import { DomSanitizer                       } from '@angular/platform-browser';
import { ActivatedRoute                     } from '@angular/router';
import { ResultadoOrcPrelComponentModel     } from './resultado.orc.prel.component.model';
import { FormBuilder                        } from '@angular/forms';

import { ResultadoOrcPrelService            } from './resultado.orc.prel.service';
import { CidadesService                     } from '../../../_services/cidades/cidades.service';
import { UfService                          } from '../../../_services/uf/uf.service';

export abstract class ResultadoOrcPrelComponent implements OnInit{
  protected activatedRoute: ActivatedRoute;
  public model: ResultadoOrcPrelComponentModel;
  protected service: ResultadoOrcPrelService;
  public resultadoOrcPrelForm;

  constructor( activatedRoute: ActivatedRoute, 
               sanitizer: DomSanitizer,
               cidadesService: CidadesService,
               ufService: UfService,
               resultadoOrcPrelService: ResultadoOrcPrelService, 
               formBuilder: FormBuilder ){
    console.log( 'ResultadoOrcPrelComponent()...' );
    this.activatedRoute = activatedRoute;
    this.service = new ResultadoOrcPrelService();

    this.model = new ResultadoOrcPrelComponentModel( sanitizer, cidadesService, ufService );

    this.activatedRoute.queryParams.subscribe( params => {
        console.log( 'activatedRoute.queryParams.subscribe...' );
        console.log( 'params: ' + Object.keys( params ) );

        this.model.setNome( params.nome );
        this.model.setTelefone( params.telefone );
        this.model.setEmail( params.email );
        this.model.setUf( params.uf );
        this.model.setNmCidade( params.nm_cidade );
        this.model.setDemandaMedMensal( params.demandaMedMensal );
        this.model.setCustoMin( params.custoMin );
        this.model.setCustoMax( params.custoMax );
        this.model.setCustoMed( params.custoMed );
        this.model.setPotPicoInvMin( params.potPicoInvMin );
        this.model.setPotPicoInvMax( params.potPicoInvMax );

        this.resultadoOrcPrelForm = formBuilder.group(
          { nome:      params.nome    ,
            telefone:  params.telefone,
            email:     params.email   ,
            uf:        params.uf      ,
            nm_cidade: params.nm_cidade,
            demandaMedMensal:   params.demandaMedMensal,
            custoMin:   params.custoMin,
            custoMax:   params.custoMax,
            custoMed:   params.custoMed,
            potPicoInvMin:   params.potPicoInvMin,
            potPicoInvMax:   params.potPicoInvMax
          }
        );
      }
    );

    console.log( 'ResultadoOrcPrelComponent().' );
  }

  abstract ngOnInit();

  protected getModel() : ResultadoOrcPrelComponentModel{
    return this.model;
  }
}
