export class Noticia{
  public url: string;
  public title: string;
  public resume: string;
  public image: any;
  public nmFonte: string;
  public dtPblccNoticia: Date;

  constructor( url: string, title: string, resume: string, image: any ){
    this.url = url;
    this.title = title;
    this.resume = resume;
    this.image = image;
  }
}
