import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AplicationStateService {
  private isSmallScreen: boolean;

  constructor() {
    console.log( 'AplicationStateService()...' );
    console.log( 'window: ' + window );

    if( window.innerWidth < 960 ){
      this.isSmallScreen = true;
    }
    else{
      this.isSmallScreen = false;
    }

    console.log( 'small screen? ' + this.isSmallScreen );
    console.log( 'AplicationStateService().' );
  }

  /**
   * Retorna true caso a tela do device seja pequena.
   */
  public isMobileResolution(): boolean {
    console.log( 'AplicationStateService.isMobileResolution()...' );
    console.log( 'isSmallScreen? ' + this.isSmallScreen );
    console.log( 'AplicationStateService.isMobileResolution().' );

    return this.isSmallScreen;
  }
}
