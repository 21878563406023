import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpBasicService             } from '../http-basic.service';
import { SessionService } from '../session/session.service';
import { DatePipe } from '@angular/common';
import { Post } from 'src/app/_models/posts/posts';


@Injectable({
  providedIn: 'root'
})
export class PostsService extends HttpBasicService< Post, Post >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe: DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Lista os posts.
   */
  list(): Promise< Post[] >{
    console.log( 'PostsService.list()...' );

    let reqData = {};

    return super.listGenericX< Post >( '/POSTS/LIST', reqData, false );
  }
}
