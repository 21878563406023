import { Component, Input, OnInit             } from '@angular/core';
import { FormBuilder                          } from '@angular/forms';
import { RealizarOrcamentoPreliminarComponent } from '../realizar.orcamento.preliminar.component';
import { Router                       		  } from '@angular/router';
import { DomSanitizer                         } from '@angular/platform-browser';

import { RealizarOrcamentoPreliminarService   } from '../realizar.orcamento.preliminar.service';
import { CidadesService                       } from '../../../../_services/cidades/cidades.service';
import { UfService                            } from '../../../../_services/uf/uf.service';
import { OrcPrelService                       } from '../../../../_services/orc/orc-prel.service';


@Component({
  selector: 'app-realizar-orcamento-preliminar-mobile',
  templateUrl: '../desktop/realizar-orcamento-preliminar-desktop.component.html',
  styleUrls: ['./realizar-orcamento-preliminar-mobile.component.css']
})
export class RealizarOrcamentoPreliminarMobileComponent extends RealizarOrcamentoPreliminarComponent {

  constructor( router: Router, 
               sanitizer: DomSanitizer,
               cidadesService: CidadesService,
               ufService: UfService,
               realizarOrcamentoPreliminarService: RealizarOrcamentoPreliminarService,
			   orcPrelService: OrcPrelService, 
               formBuilder: FormBuilder ){
    super( router, sanitizer, cidadesService, ufService, realizarOrcamentoPreliminarService, orcPrelService, formBuilder );
    console.log( 'RealizarOrcamentoPreliminarMobileComponent()' );
  }

  ngOnInit() {
    console.log( 'RealizarOrcamentoPreliminarMobileComponent.ngOnInit()' );
  }

  ngOnChanges(){
    console.log( 'RealizarOrcamentoPreliminarMobileComponent.ngOnChanges()' );
  }
}
