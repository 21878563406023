import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UfService {
  /**
   * 
   */
  constructor( private http: HttpClient ){
  }


  /**
   * Retorna uma lista contendo as siglas das UF's.
   */
  public getUfList(){
    console.log( 'getUfList()...' );

    var ufList = new Array();

    this.getUfListJson().subscribe( 
      data => { 
        for( const d of (data as any) ){
          var uf = new Object();
          uf[ 'value'     ] = d.sgl_uf;
          uf[ 'viewValue' ] = d.sgl_uf;
          uf[ 'nm' ] = d.nm_uf;

          ufList.push( uf );
        }
      },
      err => { 
        console.error(err); 
      },
      () => { 
        console.log( 'done loading uf\'s.' ) 
      }
    );

    return ufList;
  }

  private getUfListJson(){
    console.log( 'getUfListJson()...' );

    const protocolOfPage = window.location.protocol;

    const UFS_SERVICE_URL = protocolOfPage + '//ansolar-services-project.appspot.com/ufs';

    const ufListJson = this.http.get( UFS_SERVICE_URL );

    console.log( 'getUfListJson().' );

    return ufListJson;
  }
}
