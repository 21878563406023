import { Component, OnInit      } from '@angular/core';
import { Router                 } from '@angular/router';
import { DomSanitizer           } from '@angular/platform-browser';
import { PreHeaderComponent } from '../pre.header.component';

@Component({
  selector: 'app-pre-header-desktop',
  templateUrl: './pre-header.desktop.component.html',
  styleUrls: ['./pre-header.desktop.component.css']
})
export class PreHeaderDesktopComponent extends PreHeaderComponent{
  constructor( private router: Router, private sanitizer: DomSanitizer ){
    super( router, sanitizer );
  }
}
