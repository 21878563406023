import { Component, OnInit, HostListener              						} from '@angular/core';
import { state, style, animate, transition } from '@angular/animations';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { HomeComponentModel             } from './home.component.model';
import { AplicationStateService         } from '../_services/aplication-state/aplication.state.service';
import { CarousselControl, CarousselControlUsina } from './models/carousselControl';
import { CarousselManager } from './models/carousselManager';
import { Noticia } from './models/noticias';
import { NoticiasService } from '../_services/noticias/noticias.service';
import { PostsService } from '../_services/posts/posts.service';
import { Post } from '../_models/posts/posts';

export abstract class HomeComponent implements OnInit {
  private model: HomeComponentModel;

	public carousselMainManager: CarousselManager< CarousselControl >;
	public carousselUsinasManager: CarousselManager< CarousselControlUsina >;

	public heightSectionMain;

	public noticias: Noticia[] = [];

  constructor( private router: Router, 
               private sanitizer: DomSanitizer,
							 private aplicationStateService: AplicationStateService,
							 protected noticiasService: NoticiasService,
							 protected postsService: PostsService ){
    this.model = new HomeComponentModel( sanitizer );
  }

  ngOnInit(){
		//Carrega os controles do carrossel principal.
		this.initCarousselMain()
			.then( respInitCarousselMain => {
				this.onClickControlCarousselMain( 0 );
			} );

		//Carrega os controles do carrossel principal.
		this.initCarousselUsinas()
			.then( respInitCarousselUsinas => {
				this.onClickControlCarousselUsinas( 0 );
			} );

		//Carrega as noticias.
		this.initNoticias()
			.then( respInitNoticias => {
			} );

		this.recalcHeightSectionMain();
	}

  private initCarousselMain(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
			let indexSlide = 0;

			_this.carousselMainManager = new CarousselManager( 4000 );

			_this.carousselMainManager.addSlide( new CarousselControl( indexSlide++, '/assets/carousel/imagem2.png' ) );
			_this.carousselMainManager.addSlide( new CarousselControl( indexSlide++, '/assets/carousel/imagem3.png' ) );

			_this.postsService.list()
				.then( respPosts => {
					const qtPosts = respPosts.length;

					for( let indexPost = 0; indexPost < qtPosts; indexPost++ ){
						const post = respPosts[ indexPost ];

						const slide = new CarousselControl( indexSlide++, post.url );
						slide.urlSlide = post.permalink;
						slide.txDescricaoSlide = post.caption;

						_this.carousselMainManager.addSlide( slide );
					}
				} );

      resolve();
    } );

    return p;
	}

  private initNoticias(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
			_this.noticias = [];

			_this.noticiasService.list()
				.then( respNoticias => {
					respNoticias.forEach( noticiaIterate => {
						const noticia = new Noticia( noticiaIterate.txUrlNtc, noticiaIterate.txTitNtc, noticiaIterate.txDcrNtc, noticiaIterate.txUrlImgNtc );
						noticia.nmFonte = noticiaIterate.nmFntNtc;
						noticia.dtPblccNoticia = noticiaIterate.tsPblccNtc;

						_this.noticias.push( noticia );
					} );
				} );

      resolve();
    } );

    return p;
	}

	ngAfterViewInit(){
	}

	@HostListener( 'window:resize', ['$event'] )
	onResize( event ){
		this.recalcHeightSectionMain();
	}

	private recalcHeightSectionMain(): void{
		const heightOfHeaderSite = this.getHeightOfHeaderSite();
		const heightOfViewport = this.getHeightOfViewPort();

		//Calcula a altura da section principal da Home.
		const heightSectionMain = heightOfViewport - heightOfHeaderSite - 17;

		//Altera a altura da section principal da Home.
		//this.heightSectionMain = heightSectionMain + 'px';
	}

	public onClickControlCarousselMain( index: number ){
		this.carousselMainManager.selectedSlide( index, true );
	}

	public onClickPreviousControlCarousselMain(){
		this.carousselMainManager.previousSlide();
	}

	public onClickNextControlCarousselMain(){
		this.carousselMainManager.nextSlide( true );
	}

	public onClickPauseCarousselMain(){
		this.carousselMainManager.pauseTimer();
	}

	public onClickStartCarousselMain(){
		this.carousselMainManager.resetTimer();
	}

	public onClickLeftMain( event: Event ): void{
		this.carousselMainManager.previousSlide();
	}

	public onSwipeLeftMain( event: Event ): void{
		this.carousselMainManager.nextSlide( true );
	}

	public onClickRightMain( event: Event ): void{
		this.carousselMainManager.nextSlide( true );
	}

	public onSwipeRightMain( event: Event ): void{
		this.carousselMainManager.previousSlide();
	}

	public onTapMain( event: Event ): void{
		if( this.carousselMainManager.isPaused === true ){
			this.carousselMainManager.resetTimer();
		}
		else{
			this.carousselMainManager.pauseTimer();
		}
	}

  private initCarousselUsinas(): Promise< any >{
    const _this = this;

    const p = new Promise( function( resolve, reject ){
			_this.carousselUsinasManager = new CarousselManager( 4000 );

			_this.carousselUsinasManager.addSlide( new CarousselControlUsina( 0, 'assets/usinas/proj_01.png', 'Valparaíso de Goiás', 'GO', 8.71, 1130 ) );
			_this.carousselUsinasManager.addSlide( new CarousselControlUsina( 1, 'assets/usinas/proj_90.png', 'Viamão', 'RS', 3.24, 340 ) );
			_this.carousselUsinasManager.addSlide( new CarousselControlUsina( 2, 'assets/usinas/proj_25.png', 'Cachoeirinha', 'RS', 4.32, 430 ) );
			_this.carousselUsinasManager.addSlide( new CarousselControlUsina( 3, 'assets/usinas/proj_84.png', 'Novo Gama', 'GO', 23.40, 2240 ) );
			_this.carousselUsinasManager.addSlide( new CarousselControlUsina( 4, 'assets/usinas/proj_51.png', 'Cidade Ocidental', 'GO', 23.52, 2900 ) );
			_this.carousselUsinasManager.addSlide( new CarousselControlUsina( 5, 'assets/usinas/proj_136.png', 'Alvorada', 'RS', 9.81, 980 ) );

      resolve();
    } );

    return p;
	}

	public onClickControlCarousselUsinas( index: number ){
		this.carousselUsinasManager.selectedSlide( index, true );
	}

	public onClickPreviousControlCarousselUsinas(){
		this.carousselUsinasManager.previousSlide();
	}

	public onClickNextControlCarousselUsinas(){
		this.carousselUsinasManager.nextSlide( true );
	}

	public onClickPauseCarousselUsinas(){
		this.carousselUsinasManager.pauseTimer();
	}

	public onClickStartCarousselUsinas(){
		this.carousselUsinasManager.resetTimer();
	}

	public onClickLeftUsinas( event: Event ): void{
		this.carousselUsinasManager.previousSlide();
	}

	public onSwipeLeftUsinas( event: Event ): void{
		this.carousselUsinasManager.nextSlide( true );
	}

	public onClickRightUsinas( event: Event ): void{
		this.carousselUsinasManager.nextSlide( true );
	}

	public onSwipeRightUsinas( event: Event ): void{
		this.carousselUsinasManager.previousSlide();
	}

	public onTapUsinas( event: Event ): void{
		if( this.carousselUsinasManager.isPaused === true ){
			this.carousselUsinasManager.resetTimer();
		}
		else{
			this.carousselUsinasManager.pauseTimer();
		}
	}

	/**
	 * Retorna a altura do header (cabecalho) do site.
	 */
	private getHeightOfHeaderSite(): number{
		const elem = document.getElementById( 'header-of-site-id' );

		const height = elem.offsetHeight;

		return height;
	}

	/**
	 * Retorna a altura da area visivel pelo usuario (viewport).
	 */
	private getHeightOfViewPort(): number{
		const height = window.innerHeight;

		return height;
	}
}
