import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResultadoOrcPrelService {
  items = [];

  constructor(){
  }

  clear(){
  }
}
