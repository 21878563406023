import { Injectable               } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable               } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrcPrelService {
  constructor( private http: HttpClient ){
  }


  /**
   * Calcula o orcamento preliminar do sistema fotovoltaico necessario para atender aos dados
   * especificados.
   */
  public async calcOrcPrel( nome: string, telefone: string, email: string, uf: string, idCidade: number, nmCidade: string, demandaMedMensal: number ){
    console.log( 'calcOrcPrel()...' );

    const demandaMedMensalStr = demandaMedMensal != null ? demandaMedMensal.toString() : null;

    return new Promise( resolve => { 
      this.calcOrcPrelJson( demandaMedMensalStr )
      .then( ( data: any[] ) => {
        console.log( data );

        var orc = new Object();

        orc[ 'custoMin' ] = data[ 'custoMin' ];
        orc[ 'custoMax' ] = data[ 'custoMax' ];
        orc[ 'custoMed' ] = data[ 'custoMed' ];
        orc[ 'potPicoInvMin' ] = data[ 'potPicoInvMin' ];
        orc[ 'potPicoInvMax' ] = data[ 'potPicoInvMax' ];

        console.log( 'custoMin/Max/Med: ' + orc[ 'custoMin' ] + ', ' + orc[ 'custoMax' ] + ', ' + orc[ 'custoMed' ] );

        resolve( orc );
      });
    }); 
  }

  private calcOrcPrelJson( demandaMedMensal: string ){
    console.log( 'calcOrcPrelJson()...' );

    const protocolOfPage = window.location.protocol;

    const SERVICE_URL = protocolOfPage + '//ansolar-services-project.appspot.com/' + 'CALC_ORC_PREL?demandaMedMensal=' + demandaMedMensal;

    console.log( 'service - url: ' + SERVICE_URL );

    return new Promise( resolve => {
      this.http.get( SERVICE_URL )
        .subscribe( data => {
          resolve( data );
        } );
    });
  }
}