import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

export class HomeComponentModel {
  public imageUrl: SafeResourceUrl;

  constructor( private sanitizer: DomSanitizer ) {
    console.log( "Constructor 'HomeComponentModel' iniciando..." );

    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl( '' );

    console.log( "Constructor 'HomeComponentModel' iniciado." );
  }
}
