import { Component, OnInit                          } from '@angular/core';
import { DomSanitizer                               } from '@angular/platform-browser';
import { Router, NavigationExtras                   } from '@angular/router';
import { RealizarOrcamentoPreliminarComponentModel  } from './realizar.orcamento.preliminar.component.model';
import { FormBuilder                                } from '@angular/forms';

import { RealizarOrcamentoPreliminarService         } from './realizar.orcamento.preliminar.service';
import { CidadesService                             } from '../../../_services/cidades/cidades.service';
import { UfService                                  } from '../../../_services/uf/uf.service';
import { OrcPrelService                             } from '../../../_services/orc/orc-prel.service';

export abstract class RealizarOrcamentoPreliminarComponent implements OnInit{
  private router: Router;
  private model: RealizarOrcamentoPreliminarComponentModel;

  protected service: RealizarOrcamentoPreliminarService;

  public realizarOrcamentoPreliminarForm;

  constructor( router: Router, 
               sanitizer: DomSanitizer,
               cidadesService: CidadesService,
               ufService: UfService,
               realizarOrcamentoPreliminarService: RealizarOrcamentoPreliminarService,
               orcPrelService: OrcPrelService,
               formBuilder: FormBuilder ){
    this.router = router;
    this.model = new RealizarOrcamentoPreliminarComponentModel( sanitizer, cidadesService, ufService );
    this.service = new RealizarOrcamentoPreliminarService( orcPrelService );

    this.realizarOrcamentoPreliminarForm = formBuilder.group(
      { nome:     '',
        telefone: '',
        email:    '',
        uf:       'DF',
        cidade:   '',
        consumo:   ''
      }
    );

    //Atualiza a lista de cidades.
    this.updateCidadeList();
  }

  abstract ngOnInit();

  protected getModel() : RealizarOrcamentoPreliminarComponentModel{
    return this.model;
  }

  public async realizarOrcamentoPreliminar( nome: string, telefone: string, email: string, uf: string, cidade: string, demandaMedMensal: number ){
    const orc = await this.service.realizarOrcamentoPreliminar( nome, telefone, email, uf, cidade, demandaMedMensal );

    if( orc != null ){
      console.log( 'orc: ' + Object.keys( orc ) );

      const custoMin = orc[ 'custoMin' ];
      const custoMax = orc[ 'custoMax' ];
      const custoMed = orc[ 'custoMed' ];
      const potPicoInvMin = orc[ 'potPicoInvMin' ];
      const potPicoInvMax = orc[ 'potPicoInvMax' ];

      console.log( 'custoMin/custoMax/custoMed: ' + custoMin + '/' + custoMax + '/' + custoMed );

      //Cria objeto contendo os dados do orcamento.
      let navigationExtras: NavigationExtras = {
          queryParams:{ 
            'nome'     : nome    ,
            'telefone' : telefone,
            'email'    : email   ,
            'uf'       : uf      ,
            'nm_cidade'       : cidade          ,
            'demandaMedMensal': demandaMedMensal,
            'custoMin' : custoMin,
            'custoMax' : custoMax,
            'custoMed' : custoMed,
            'potPicoInvMin' : potPicoInvMin,
            'potPicoInvMax' : potPicoInvMax
          },

          skipLocationChange: true
      };

      this.router.navigate( [ '/resultado-orc-prel' ], navigationExtras );
    }
  }

  public ufChangeEventAction( ufSelectedEvent ) : void{
    console.log( 'ufChangeEventAction()...' );

    this.updateCidadeList();

    console.log( 'ufChangeEventAction().' );
  }

  /**
   * Atualiza a lista de cidades apresentada no form, conforme a 
   * UF selecionada.
   */
  protected updateCidadeList(){
    console.log( 'RealizarOrcamentoPreliminarComponent.updateCidadeList()...' );

    const ufSelected = this.realizarOrcamentoPreliminarForm.value.uf;

    console.log( 'UF Selected: ' + ufSelected );

    let model = this.getModel();
    let cidadeListLocal = model.getCidadeListOfUf( ufSelected );

    model.setCidadeList( cidadeListLocal );

    console.log( 'RealizarOrcamentoPreliminarComponent.updateCidadeList().' );
  }
}
