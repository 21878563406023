import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, Router, PreloadAllModules } from '@angular/router';


/* Import de services */
import { AplicationStateService                       } from './_services/aplication-state/aplication.state.service';

/* Import de componentes */
import { HomeDesktopComponent                         } from './home/desktop/home.desktop.component';
import { HomeMobileComponent                          } from './home/mobile/home.mobile.component';
import { PageNotFoundDesktopComponent                 } from './page-not-found/desktop/page-not-found.desktop.component';
import { PageNotFoundMobileComponent                  } from './page-not-found/mobile/page-not-found.mobile.component';
import { QuemSomosDesktopComponent                    } from './quem-somos/desktop/quem-somos-desktop.component';
import { QuemSomosMobileComponent                     } from './quem-somos/mobile/quem-somos-mobile.component';
import { RealizarOrcamentoPreliminarDesktopComponent  } from './orcamento/preliminar/realizar-orc-prel/desktop/realizar-orcamento-preliminar-desktop.component';
import { RealizarOrcamentoPreliminarMobileComponent   } from './orcamento/preliminar/realizar-orc-prel/mobile/realizar-orcamento-preliminar-mobile.component';
import { ResultadoOrcPrelDesktopComponent             } from './orcamento/preliminar/resultado-orc-prel/desktop/resultado-orc-prel-desktop.component';
import { ResultadoOrcPrelMobileComponent              } from './orcamento/preliminar/resultado-orc-prel/mobile/resultado-orc-prel-mobile.component';

const desktop_routes: Routes = [
  { path: ''                              , redirectTo: '/home', pathMatch: 'full' },
  { path: 'home'                          , component: HomeDesktopComponent                        },
  { path: 'quem-somos'                    , component: QuemSomosDesktopComponent                   },
  { path: 'realizar-orcamento-preliminar' , component: RealizarOrcamentoPreliminarDesktopComponent },
  { path: 'resultado-orc-prel'            , component: ResultadoOrcPrelDesktopComponent            },
  { path: 'resultado-orc-prel'            , component: ResultadoOrcPrelDesktopComponent            },
  { path: '**'                            , component: PageNotFoundDesktopComponent                }
];

const mobile_routes: Routes = [
  { path: ''                              , redirectTo: '/home', pathMatch: 'full' },
  { path: 'home'                          , component: HomeMobileComponent                         },
  { path: 'quem-somos'                    , component: QuemSomosMobileComponent                    },
  { path: 'realizar-orcamento-preliminar' , component: RealizarOrcamentoPreliminarMobileComponent  },
  { path: 'resultado-orc-prel'            , component: ResultadoOrcPrelMobileComponent             },
  { path: '**'                            , component: PageNotFoundMobileComponent                 }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forRoot( desktop_routes, { preloadingStrategy: PreloadAllModules } )
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { 
  public constructor( private router: Router,
                      private aplicationStateService: AplicationStateService ) {
    console.log( 'AppRoutingModule()...' );

    if( aplicationStateService.isMobileResolution() ) {
      console.log( 'is small screen.' );

      router.resetConfig( mobile_routes );

      console.log( 'Router reseted for mobile_routes.' );
    } else {
      console.log( 'is big screen.' );
    }

    console.log( 'AppRoutingModule().' );
  }
}
