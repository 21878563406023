import { Injectable                   } from '@angular/core';
import { BehaviorSubject, Observable  } from 'rxjs';

import { User                         } from '../../_models/user';
import { PrmUsr                       } from '../../_models/prmUsr';

@Injectable({
  providedIn: 'root'
})
export class SessionService{
  public currentUserSubject: BehaviorSubject< User >;
  public currentUser       : Observable< User >;

  //Parametros associados ao usuario.
  private prmsOfUsr = null;

  constructor(){
    this.currentUserSubject = new BehaviorSubject<User>( JSON.parse( localStorage.getItem( 'currentUser' ) ) );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * Retorna o nome do usuário.
   */
  public get currentUserName(): String {
    const user = this.currentUserValue;

    if( user == null ){
      return "";
    }
    else{
      return user.name;
    }
  }

  /**
   * Retorna true caso o usuário esteja logado, caso contrário, false.
   */
  public isUserLogged(): boolean {
    const _isLogged = this.currentUserValue != null;

    return _isLogged;
  }

  public clearPrmUsr(){
    this.prmsOfUsr = {};
  }

  /**
   * Altera o valor associado ao parametro do usuario.
   * @param sglPrmUsr 
   * @param txPrmUsr 
   */
  public setPrmUsr( sglPrmUsr: string, txPrmUsr: string ){
    //console.log( 'sessionService.setPrmUsr( ' + sglPrmUsr + ', ' + txPrmUsr + ' )...' );

    const prmUsr = new PrmUsr();
    prmUsr.user = this.currentUserValue;
    prmUsr.sglPrmUsr = sglPrmUsr;
    prmUsr.txPrmUsr  = txPrmUsr;

    if( !this.prmsOfUsr || this.prmsOfUsr == null ){
      this.prmsOfUsr = {};
    }

    this.prmsOfUsr[ sglPrmUsr ] = prmUsr;
  }

  /**
   * Retorna o valor do parametro associado ao usuario que possua a chave especificada.
   */
  public getValueOfPrmUsr( sglPrmUsr: string ): string{
    const prmUsr = ( this.prmsOfUsr && this.prmsOfUsr != null ) ? this.prmsOfUsr[ sglPrmUsr ] : null;

    return prmUsr != null ? prmUsr.txPrmUsr : null;
  }
}
