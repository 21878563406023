import { Component, Input, OnInit  } from '@angular/core';
import { FormBuilder               } from '@angular/forms';
import { ResultadoOrcPrelComponent } from '../resultado.orc.prel.component';
import { ActivatedRoute            } from '@angular/router';
import { DomSanitizer              } from '@angular/platform-browser';

import { ResultadoOrcPrelService   } from '../resultado.orc.prel.service';
import { CidadesService            } from '../../../../_services/cidades/cidades.service';
import { UfService                 } from '../../../../_services/uf/uf.service';


@Component({
  selector: 'app-resultado-orc-prel-desktop',
  templateUrl: './resultado-orc-prel-desktop.component.html',
  styleUrls: ['./resultado-orc-prel-desktop.component.css']
})
export class ResultadoOrcPrelDesktopComponent extends ResultadoOrcPrelComponent {
  constructor( activatedRoute: ActivatedRoute, 
               sanitizer: DomSanitizer,
               cidadesService: CidadesService,
               ufService: UfService,
               resultadoOrcPrelService: ResultadoOrcPrelService, 
               formBuilder: FormBuilder ){
    super( activatedRoute, sanitizer, cidadesService, ufService, resultadoOrcPrelService, formBuilder );
  }

  ngOnInit(){
    console.log( 'ngOnInit()...' );
    console.log( 'app-resultado-orc-prel-desktop' );
    console.log( 'ngOnInit().' );
  }

  onFocusEventAction() : void{
    console.log( 'onFocusEventAction()...' );
  }

  onBlurEventAction() : void{
    console.log( 'onBlurEventAction()...' );
  }
}
