import { Component, OnInit      } from '@angular/core';
import { Router                 } from '@angular/router';
import { DomSanitizer           } from '@angular/platform-browser';
import { HeaderComponentModel   } from '../header.component.model';

@Component({
  selector: 'app-header-mobile',
  templateUrl: '../desktop/header.desktop.component.html',
  styleUrls: ['./header.mobile.component.css']
})
export class HeaderMobileComponent implements OnInit {
  private model: HeaderComponentModel;

  constructor( private router: Router, 
               private sanitizer: DomSanitizer ){
    this.model = new HeaderComponentModel( sanitizer );
  }

  ngOnInit(){
    console.log( "Component 'app-header-mobile' iniciado." );
  }
}
