import { Component, Input, OnInit  } from '@angular/core';
import { FormBuilder               } from '@angular/forms';
import { ResultadoOrcPrelComponent } from '../resultado.orc.prel.component';
import { ActivatedRoute            } from '@angular/router';
import { DomSanitizer              } from '@angular/platform-browser';

import { ResultadoOrcPrelService   } from '../resultado.orc.prel.service';
import { CidadesService            } from '../../../../_services/cidades/cidades.service';
import { UfService                 } from '../../../../_services/uf/uf.service';


@Component({
  selector: 'app-resultado-orc-prel-mobile',
  templateUrl: './resultado-orc-prel-mobile.component.html',
  styleUrls: ['./resultado-orc-prel-mobile.component.css']
})
export class ResultadoOrcPrelMobileComponent extends ResultadoOrcPrelComponent {

  constructor( activatedRoute: ActivatedRoute, 
               sanitizer: DomSanitizer,
               cidadesService: CidadesService,
               ufService: UfService,
               resultadoOrcPrelService: ResultadoOrcPrelService, 
               formBuilder: FormBuilder ){
    super( activatedRoute, sanitizer, cidadesService, ufService, resultadoOrcPrelService, formBuilder );
    console.log( 'ResultadoOrcPrelMobileComponent()' );
  }

  ngOnInit() {
    console.log( 'ResultadoOrcPrelMobileComponent.ngOnInit()' );
  }

  ngOnChanges(){
    console.log( 'ResultadoOrcPrelMobileComponent.ngOnChanges()' );
  }
}
