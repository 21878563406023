import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

/* Import de services */
import { CidadesService } from '../../../_services/cidades/cidades.service';
import { UfService      } from '../../../_services/uf/uf.service';


export interface UF{
  value: string;
  viewValue: string;
}

export interface Cidade{
  uf: string;
  nome: string;
}


export class RealizarOrcamentoPreliminarComponentModel {
  private cidadesService;
  private ufService;

  public imageUrl: SafeResourceUrl;

  private ufList: UF[] = null;
  private cidadeList: Cidade[] = [];

  constructor( private sanitizer: DomSanitizer, private cidadesServiceLocal: CidadesService, private ufServiceLocal: UfService ) {
    this.cidadesService = cidadesServiceLocal;
    this.ufService = ufServiceLocal;

    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl( '' );

    //Carrega lista de UF's.
    const ufListLocal = this.getUfList();

    //this.setUfList( ufListLocal );
  }

  public getUfList() : UF[]{
    if( this.ufList == null ){
      this.ufList = this.ufService.getUfList();
    }

    return this.ufList;
  }

  public setUfList( ufListLocal : UF[] ){
    this.ufList = ufListLocal;
  }

  public getCidadeList() : Cidade[]{
    return this.cidadeList;
  }

  public setCidadeList( cidadeListLocal : Cidade[] ){
    this.cidadeList = cidadeListLocal;
  }

  public getCidadeListOfUf( uf : String ) : Cidade[]{
    let cidades = this.cidadesService.getCidadesOfUf( uf );

    return cidades;
  }
}
