import { Injectable                   } from '@angular/core';
import { HttpClient, HttpHeaders      } from '@angular/common/http';

import { ResponseApiGeneric, ResponseApiGenericException           } from '../_models/apiService';

import { environment                  } from '../../environments/environment';

import { SessionService               } from '../_services/session/session.service';
import { ThrowStmt } from '@angular/compiler';


@Injectable({
  providedIn: 'root'
})
export abstract class HttpBasicService< D, L >{
  constructor( protected http          : HttpClient,
               protected sessionService: SessionService ) {
  }


  /**
   * Detalha um registro.
   */
  detailGeneric( serviceAddress: string, reqData: Object, isMethodPost: boolean ) : Promise< D > {
    return this.detailGenericX< D >( serviceAddress, reqData, isMethodPost );
  }


  /**
   * Detalha um registro.
   */
  detailGenericX< X >( serviceAddress: string, reqData: Object, isMethodPost: boolean ) : Promise< X > {
    //console.log( 'detailGenericX( ' + serviceAddress + ' )...' );

    //Executa servico.
    const respJson = ( isMethodPost && isMethodPost === true ) ? this.reqPostJsonGeneric( serviceAddress, reqData ) : this.reqGetJsonGeneric( serviceAddress, reqData );

    let _this = this;

    return new Promise< X >( function( resolve, reject ) {
      respJson
        .then( dataResp => {
          const cResp = dataResp[ 'codResp' ].valueOf();

          //console.log( 'cResp: ' + cResp );

          if( cResp != null && cResp == 0 ){
            const dResp = dataResp[ 'dataResp' ];

            resolve( dResp );
          }
          else{
            reject( dataResp );
          }
        })
        .catch( error => {
          console.log( error );
          reject( error );
        } );
    });
  }


  /**
   * Listar registros.
   */
  listGeneric( serviceAddress: string, reqData: Object, isMethodPost: boolean ): Promise< L[] >{
    return this.listGenericX< L >( serviceAddress, reqData, isMethodPost );
  }


  /**
   * Listar registros.
   */
  listGenericX< X >( serviceAddress: string, reqData: Object, isMethodPost: boolean ): Promise< X[] >{
    //Executa servico.
    const respJsonGen = ( isMethodPost && isMethodPost === true ) ? this.reqPostJsonGeneric( serviceAddress, reqData ) : this.reqGetJsonGeneric( serviceAddress, reqData );

    let _this = this;

    let objList = new Array< X >();

    return new Promise< X[] >( function( resolve, reject ) {
      respJsonGen.then( rag => {
        let dataResp = rag.dataResp;

        for( const dR of (dataResp as any) ){
          objList.push( dR );
        }

        resolve( objList );
      })
      .catch( error => {
        reject( error );
      } );
    });
  }

  /**
   * Executa uma requisição utilizando o metodo GET.
   * @param serviceAddress O endereco do servico, normalmente iniciando por '/', o qual vem depois do nome de dominio.
   * @param objData Os dados a serem enviados via post.
   */
  protected reqGetJsonGeneric( serviceAddress: string, objData: Object ) : Promise< ResponseApiGeneric >{
    let p = this.reqGetJson( serviceAddress, objData );

    return new Promise< ResponseApiGeneric >( function( resolve, reject ) {
      p.then( dataResp => {
        let rag = new ResponseApiGeneric();
        rag.codResp  = dataResp[ 'codResp' ];
        rag.msgResp  = dataResp[ 'msgResp' ];
        rag.dataResp = dataResp[ 'dataResp' ];

        if( rag.codResp === 0 ){
          resolve( rag );
        }
        else{
          let err = new ResponseApiGenericException(  );
          err.codErr = rag.codResp;
          err.msgErr = rag.msgResp;

          reject( err );
        }
      });
    } );
  }

  /**
   * Executa uma requisição utilizando o metodo GET.
   * @param serviceAddress O endereco do servico, normalmente iniciando por '/', o qual vem depois do nome de dominio.
   * @param objData Os dados a serem enviados via post.
   */
  protected reqGetJson( serviceAddress: string, objData: Object ){
    const protocolOfPage = window.location.protocol;
    const apiDomain = environment.apiUrl;

    let parameters = '';

    //Adiciona os parametros aa URL.
    const keys   = objData != null ? Object.keys( objData )   : null;
    const values = objData != null ? Object.values( objData ) : null;
    const keysLength = keys != null ? keys.length : 0;

    for( var index = 0; index < keysLength; index++ ){
      const key = keys[ index ];
      const value = values[ index ];

      parameters += '&' + key + '=' + value;
    }

    const SERVICE_URL = protocolOfPage + apiDomain + serviceAddress + '?' + parameters;

    return new Promise( resolve => {
      this.http.get( SERVICE_URL )
        .subscribe( dataResp => {
          resolve( dataResp );
        } );
      }
    );
  }

  /**
   * Executa uma requisição utilizando o metodo POST.
   * @param serviceAddress O endereco do servico, normalmente iniciando por '/', o qual vem depois do nome de dominio.
   * @param objData Os dados a serem enviados via post.
   */
  protected reqPostJsonGeneric( serviceAddress: string, objData: Object ) : Promise< ResponseApiGeneric >{
    let p = this.reqPostJson( serviceAddress, objData );

    return new Promise< ResponseApiGeneric >( function( resolve, reject ) {
      p.then( dataResp => {
        let rag = new ResponseApiGeneric();
        rag.codResp  = dataResp[ 'codResp' ];
        rag.msgResp  = dataResp[ 'msgResp' ];
        rag.dataResp = dataResp[ 'dataResp' ];

        if( rag.codResp === 0 ){
          resolve( rag );
        }
        else{
          console.log( 'err' );

          let err = new ResponseApiGenericException(  );
          err.codErr = rag.codResp;
          err.msgErr = rag.msgResp;

          reject( err );
        }
      })
      .catch( error => {
        console.error( 'reqPostJsonGeneric.error: ' + error );
      });
    } );
  }

  /**
   * Executa uma requisição utilizando o metodo POST.
   * @param serviceAddress O endereco do servico, normalmente iniciando por '/', o qual vem depois do nome de dominio.
   * @param objData Os dados a serem enviados via post.
   */
  protected reqPostJson( serviceAddress: string, objData ){
    const protocolOfPage = window.location.protocol;
    const apiDomain      = environment.apiUrl;

    const API_DOMAIN  = protocolOfPage + apiDomain;
    const SERVICE_URL = API_DOMAIN + serviceAddress;

    const headersValues = new HttpHeaders();

    headersValues.append('Content-Type', 'application/json');
    headersValues.append('Accept'      , 'application/json');

    headersValues.append( 'Access-Control-Allow-Origin'     , SERVICE_URL );
    headersValues.append( 'Access-Control-Allow-Credentials', 'true' );

    const httpOptions = {
      headers: headersValues
    };

    //Se usuario estiver logado, adiciona os dados da sessao no corpo da requisicao.
    if( this.sessionService.isUserLogged() === true ){
      //console.log( 'user is logged.' );

      objData.cd_usr = this.sessionService.currentUserValue.id;
      objData.token  = this.sessionService.currentUserValue.token;
    }

    return new Promise( resolve => {
      //Converte o JSON numa string.
      const objDataJsonStringify = JSON.stringify( objData );

      //Converte os dados para padrao de URI.
      const objDataEncodeUri = encodeURI( objDataJsonStringify );

      //Converte os dados que irao no body da requisicao para base64.
      const objDataEncoded = window.btoa( objDataEncodeUri );

      //Gera um objeto JSON com o conteudo convertido para base64.
      const objDataBody = { 'payload': objDataEncoded };

      //Realiza a requisicao do servico transmitindo os dados do body em base64.
      this.http.post( SERVICE_URL, objDataBody, httpOptions )
        .subscribe( data => {
          resolve( data );
        } );
      }
    );
  }
}
