import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FooterComponentModel } from '../footer.component.model';
import { FooterComponent } from '../footer.component';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: '../desktop/footer.desktop.component.html',
  styleUrls: ['./footer.mobile.component.css']
})
export class FooterMobileComponent extends FooterComponent {

  constructor( router: Router, 
               sanitizer: DomSanitizer ){
    super( router, sanitizer );
  }

  ngOnInit() {
    console.log( 'Component \'app-footer-mobile\' inciado...' );
  }
}
