import { Component } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { QuemSomosComponent } from '../quem.somos.component';

@Component({
  selector: 'app-quem-somos-desktop',
  templateUrl: './quem-somos-desktop.component.html',
  styleUrls: ['./quem-somos-desktop.component.css']
})
export class QuemSomosDesktopComponent extends QuemSomosComponent {
  constructor( router: Router,
               sanitizer: DomSanitizer ) {
    super( router, sanitizer );
  }
}
