import { Component } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PageNotFoundComponent } from '../page-not-found.component';

@Component({
  selector: 'app-page-not-found-mobile',
  templateUrl: '../desktop/page-not-found.desktop.component.html',
  styleUrls: ['./page-not-found.mobile.component.css']
})
export class PageNotFoundMobileComponent extends PageNotFoundComponent {
  constructor( router: Router,
               sanitizer: DomSanitizer ) {
    super( router, sanitizer );
  }
}
