import { NgModule                               } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from '@angular/material';
import { BrowserAnimationsModule				} from '@angular/platform-browser/animations';
import { HttpClientModule                       } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule       } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DatePipe                               } from '@angular/common';


/* ----------------- Biblioteca Material ----------------- */
import { MatButtonModule 			} from '@angular/material';
import { MatButtonToggleModule		} from '@angular/material/button-toggle';
import { MatCardModule 				} from '@angular/material/card';
import { MatFormFieldModule 		} from '@angular/material/form-field';
import { MatIconModule 				} from '@angular/material/icon';
import { MatInputModule 			} from '@angular/material/input';
import { MatListModule 				} from '@angular/material';
import { MatProgressSpinnerModule	} from '@angular/material/progress-spinner';
import { MatSelectModule 			} from '@angular/material/select';
import { MatSidenavModule 			} from '@angular/material/sidenav';
import { MatStepperModule			} from '@angular/material/stepper';
import { MatTableModule 			} from '@angular/material/table';
import { MatToolbarModule 			} from '@angular/material/toolbar';

/* ----------------- Biblioteca Flex-Layout ----------------- */
import { FlexLayoutModule 		} from '@angular/flex-layout';

import { AppRoutingModule 		} from './app-routing.module';


/* ----------------- Services ----------------- */
import { AplicationStateService } from './_services/aplication-state/aplication.state.service';
import { CidadesService         } from './_services/cidades/cidades.service';
import { OrcPrelService         } from './_services/orc/orc-prel.service';
import { UfService      	    } from './_services/uf/uf.service';
import { SessionService         } from './_services/session/session.service';


/* ----------------- Componentes Gerais ----------------- */
import { AppComponent 						 	} from './app/app.component';

/* ----------------- Componentes Desktop ----------------- */
import { ContactsBarDesktopComponent					} from './contacts-bar/desktop/contacts-bar.desktop.component';
import { FooterDesktopComponent							} from './footer/desktop/footer.desktop.component';
import { HeaderDesktopComponent	 						} from './header/desktop/header.desktop.component';
import { HomeDesktopComponent 							} from './home/desktop/home.desktop.component';
import { PageNotFoundDesktopComponent					} from './page-not-found/desktop/page-not-found.desktop.component';
import { PreHeaderDesktopComponent	 					} from './pre-header/desktop/pre-header.desktop.component';
import { QuemSomosDesktopComponent						} from './quem-somos/desktop/quem-somos-desktop.component';
import { RealizarOrcamentoPreliminarDesktopComponent	} from './orcamento/preliminar/realizar-orc-prel/desktop/realizar-orcamento-preliminar-desktop.component';
import { ResultadoOrcPrelDesktopComponent				} from './orcamento/preliminar/resultado-orc-prel/desktop/resultado-orc-prel-desktop.component';


/* ----------------- Componentes Mobile ----------------- */
import { ContactsBarMobileComponent						} from './contacts-bar/mobile/contacts-bar.mobile.component';
import { FooterMobileComponent 							} from './footer/mobile/footer.mobile.component';
import { HeaderMobileComponent	 						} from './header/mobile/header.mobile.component';
import { HomeMobileComponent 							} from './home/mobile/home.mobile.component';
import { PageNotFoundMobileComponent					} from './page-not-found/mobile/page-not-found.mobile.component';
import { PreHeaderMobileComponent	 					} from './pre-header/mobile/pre-header.mobile.component';
import { QuemSomosMobileComponent 						} from './quem-somos/mobile/quem-somos-mobile.component';
import { RealizarOrcamentoPreliminarMobileComponent		} from './orcamento/preliminar/realizar-orc-prel/mobile/realizar-orcamento-preliminar-mobile.component';
import { ResultadoOrcPrelMobileComponent				} from './orcamento/preliminar/resultado-orc-prel/mobile/resultado-orc-prel-mobile.component';

export class CustomHammerConfig extends GestureConfig  {
buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-y'
    });

    return mc;
  }
}

@NgModule({
  imports: [
    BrowserModule          	,
    BrowserAnimationsModule	,

	HttpClientModule		,
    AppRoutingModule       	,
    FormsModule            	,
    ReactiveFormsModule    	,
    
	MatButtonModule        	,
	MatButtonToggleModule	,
	MatCardModule        	,
    MatFormFieldModule     	,
    MatIconModule          	,
    MatInputModule			,
	MatListModule			,
	MatProgressSpinnerModule,
    MatSelectModule        	,
	MatSidenavModule		,
	MatStepperModule		,
    MatTableModule         	,
	MatToolbarModule		,

	FlexLayoutModule
  ],

  declarations: [
    AppComponent                				,
    ContactsBarDesktopComponent      			,
    ContactsBarMobileComponent      			,
    FooterDesktopComponent      				,
    FooterMobileComponent       				,
    HeaderDesktopComponent      				,
    HeaderMobileComponent       				,
    HomeDesktopComponent        				,
    HomeMobileComponent         				,
    PageNotFoundDesktopComponent				,
    PageNotFoundMobileComponent					,
    PreHeaderDesktopComponent      				,
    PreHeaderMobileComponent       				,
    QuemSomosDesktopComponent   				,
    QuemSomosMobileComponent    				,
    RealizarOrcamentoPreliminarDesktopComponent	,
    RealizarOrcamentoPreliminarMobileComponent  ,
    ResultadoOrcPrelDesktopComponent	        ,
    ResultadoOrcPrelMobileComponent
  ],

  providers: [
	{provide: LocationStrategy	, useClass: HashLocationStrategy},
	AplicationStateService		,
	CidadesService				,
	OrcPrelService				,
	UfService					,
	SessionService,
	DatePipe,
	{provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig}
  ],

  bootstrap: [
    AppComponent
  ],

  entryComponents:[
	HomeDesktopComponent                      ,
	HomeMobileComponent                       ,
	PageNotFoundMobileComponent					,
	QuemSomosDesktopComponent                 ,
	QuemSomosMobileComponent                  ,
	RealizarOrcamentoPreliminarMobileComponent,
	ResultadoOrcPrelMobileComponent
  ]
})
export class AppModule { }
