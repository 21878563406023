import { Injectable } from '@angular/core';

import { OrcPrelService                             } from '../../../_services/orc/orc-prel.service';

@Injectable({
  providedIn: 'root'
})
export class RealizarOrcamentoPreliminarService {
  protected orcPrelService: OrcPrelService;

  items = [];

  constructor( orcPrelService: OrcPrelService ){
    this.orcPrelService = orcPrelService;
  }

  public async realizarOrcamentoPreliminar( nome: string, telefone: string, email: string, uf: string, nmCidade: string, demandaMedMensal: number ){
    console.info( 'nome/telefone/e-mail/uf/cidade/consumo: ' + nome + '/' + telefone + '/' + email + '/' + uf + '/' + 
                  nmCidade + '/' + demandaMedMensal );

    if( nome == null || nome === '' ){
      window.alert( 'Nome não informado.' );

      return null;
    }
    else{
      if( telefone == null || telefone == '' ){
        window.alert( 'Telefone não informado.' );

        return null;
      }
      else{
        if( email == null || email == '' ){
          window.alert( 'E-mail não informado.' );

          return null;
        }
        else{
          if( demandaMedMensal == null || demandaMedMensal <= 0 ){
            window.alert( 'Consumo médio mensal não informado.' );

            return null;
          }
          else{
            //Chama API para que realize o calculo do orcamento.
            const orc = await this.orcPrelService.calcOrcPrel( nome, telefone, email, uf, null, nmCidade, demandaMedMensal );

            console.log( 'API retornou orcamento: ' + Object.keys( orc ) );

            return orc;
          }
        }
      }
    }
  }

  clear(){
  }
}
