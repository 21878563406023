import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DatePipe } from '@angular/common';
import { Noticia } from 'src/app/_models/noticias/noticias';
import { HttpBasicService } from '../http-basic.service';
import { SessionService } from '../session/session.service';


@Injectable({
  providedIn: 'root'
})
export class NoticiasService extends HttpBasicService< Noticia, Noticia >{
  constructor( protected http: HttpClient,
               protected sessionService: SessionService,
               protected datePipe: DatePipe ) {
    super( http, sessionService );
  }

  /**
   * Lista as noticias.
   */
  list(): Promise< Noticia[] >{
    let reqData = { 'limit': 9 };

    return super.listGenericX< Noticia >( '/NTC/LIST', reqData, false );
  }
}
