import { Component } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppComponentModel              } from './app.component.model';
import { AplicationStateService         } from '../_services/aplication-state/aplication.state.service';
import 'hammerjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  private model: AppComponentModel;
  public isMobileResolution: boolean;

  constructor( private aplicationStateService: AplicationStateService ) {
    console.log( "Construtor 'AppComponent()'..." );

    this.model = new AppComponentModel();

    this.isMobileResolution = this.aplicationStateService.isMobileResolution();

    console.log( "Construtor 'AppComponent()'." );
  }
}