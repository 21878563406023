import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { PreHeaderComponentModel } from './pre.header.component.model';

export abstract class PreHeaderComponent implements OnInit{
  private model: PreHeaderComponentModel;

  constructor( router: Router, sanitizer: DomSanitizer ){
    this.model = new PreHeaderComponentModel( sanitizer );
  }

  ngOnInit() {
    console.log( 'Component \'pre-header\' iniciado...' );
  }
}
