import { Component, OnInit                      } from '@angular/core';
import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PageNotFoundComponentModel } from './page-not-found.component.model';

export class PageNotFoundComponent implements OnInit {
  private model: PageNotFoundComponentModel;

  constructor( private router: Router, 
               private sanitizer: DomSanitizer ){
    this.model = new PageNotFoundComponentModel( sanitizer );
  }

  ngOnInit() {
    console.log( 'Component \'page-not-found\' inciado...' );
  }
}
