import { CarousselControl } from './carousselControl';
import { Subscription, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { resolve } from 'url';

export class CarousselManager< C extends CarousselControl >{
  public slides: C[];
  private indexSlideViewed = -1;

  public isPaused: boolean = null;
  private timerInterval: number;
  private timerSubscription: Subscription = null;

  public constructor( timerInterval: number ){
    this.slides = [];

    this.timerInterval = timerInterval;
    this.resetTimer();
  }

  public resetTimer(): void{
    if( this.timerSubscription != null ){
      this.timerSubscription.unsubscribe();
    }

    //timer( 0, ms ) call the function immediately and every 'ms/1000' seconds.
    this.timerSubscription = timer( this.timerInterval, this.timerInterval ).pipe(
      map( () => {
        this.nextSlide( false );
      } )
    ).subscribe();

    this.isPaused = false;
  }

  public getSlide( index: number ): C{
    return this.slides[ index ];
  }

  public getSlidesWithImage(): C[]{
    const slidesWithImage = [];

    this.slides.forEach( slide => {
      if( slide.imageSrc != null && slide.imageSrc != '' ){
        slidesWithImage.push( slide );
      }
    } );

    return slidesWithImage;
  }

  public addSlide( slide: C ): void{
    if( this.slides.length === 1 ){
      slide.isViewed = true;
      slide.state = 'show';
      this.indexSlideViewed = 0;
    }
    else{
      slide.state = 'slipToRight';
    }

    this.slides.push( slide );
  }

  public selectedSlide( index: number, isResetTimer: boolean  ): C{
    const qtSlides: number = this.slides != null ? this.slides.length : 0;
    const indexMax: number = qtSlides - 1;

    let slideSelected = null;

    if( index <= indexMax ){
      //Obtem o slide atual.
      const slideActual = this.slides[ this.indexSlideViewed ];

      //Altera o isViewed do slide atual para false.
      slideActual.isViewed = false;

      //Itera por todos os slides, definindo o novo status.
      for( let indexSlide = 0; indexSlide < qtSlides; indexSlide++ ){
        let slide = this.slides[ indexSlide ];

        //Define o sentido do deslocameno.
        const offset: number = indexSlide - index;

        //Define o novo estado, tempo da animacao e o deslocamento.
        slide.state = offset === 0 ? 'show' : offset > 0 ? 'slipToRight' : 'slipToLeft';

        //Calcula qual devera ser a nova posicao deste slide.
        const nextOffset: number = ( 100 * offset );

        //Calcula quanto o slide ira se deslocar.
        const offsetThisSlide = nextOffset - slide.offsetOfSlide;

        slide.offsetOfSlide    = nextOffset;
        slide.offsetStrOfSlide = nextOffset + 'vw';

        //Calcula em quanto tempo devera se deslocar.
        slide.timeStrOfAnimation = '1s';
      }

      //Altera o index do slide que esta sendo visualizado.
      this.indexSlideViewed = index;

      //Obtem o slide selecionado.
      slideSelected = this.slides[ index ];

      //Altera o isViewed do slide para true.
      slideSelected.isViewed = true;

      if( this.isPaused === false && isResetTimer === true ){
        this.resetTimer();
      }
    }

    return slideSelected;
  }

  public previousSlide(): C{
    const qtSlides: number = this.slides != null ? this.slides.length : 0;
    let slidePrevious = null;

    if( qtSlides > 0 ){
      const indexMax: number = qtSlides - 1;

      //Calcula o slide anterior.
      const previousIndexControl = this.indexSlideViewed <= 0 ? indexMax : this.indexSlideViewed - 1;

      //Obtem o slide anterior.
      slidePrevious = this.selectedSlide( previousIndexControl, true );
    }

    return slidePrevious;
  }

  public nextSlide( isResetTimer: boolean ): C{
    const qtSlides: number = this.slides != null ? this.slides.length : 0;
    let slideNext = null;

    if( qtSlides > 0 ){
      const indexMax: number = qtSlides - 1;

      //Calcula o proximo slide.
      const nextIndexControl = this.indexSlideViewed >= indexMax ? 0 : this.indexSlideViewed + 1;

      //Obtem o proximo slide.
      slideNext = this.selectedSlide( nextIndexControl, isResetTimer );
    }

    return slideNext;
  }

  public pauseTimer(): void{
    if( this.timerSubscription != null ){
      this.isPaused = true;
      this.timerSubscription.unsubscribe();
    }
  }
}
