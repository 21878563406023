import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CidadesService {
  /**
   * 
   */
  constructor( private http: HttpClient ){
  }

  /**
   * Retorna uma lista contendo os nomes das cidades pertencetentes à UF especificada.
   * @param uf Sigla da UF a ser pesquisada.
   */
  public getCidadesOfUf( uf: string ){
    console.log( 'getCidadesOfUf( ' + uf + ' )...' );

    var cidadesList = new Array();

    this.getCidadesOfUfJson( uf ).subscribe( 
      data => { 
        for( const d of (data as any) ){
          console.log( 'for( ' + d + ' )...' );

          var cidade = new Object();
          cidade[ 'uf' ] = uf;
          cidade[ 'id_cidade' ] = d.id_cidade;
          cidade[ 'nome' ] = d.nm_cidade;

          cidadesList.push( cidade );
        }
      },
      err => { 
        console.error(err); 
      },
      () => { 
        console.log( 'done loading cidades.' ) 
      }
    );

    console.log( 'getCidadesOfUf().' );

    return cidadesList;
  }

  private getCidadesOfUfJson( uf: string ){
    console.log( 'getCidadesOfUfJson( ' + uf + ' )...' );

    const protocolOfPage = window.location.protocol;

    const CIDADES_SERVICE_URL = protocolOfPage + '//ansolar-services-project.appspot.com/cidadesOfUf?uf=' + uf;

    const cidadesJson = this.http.get( CIDADES_SERVICE_URL );

    console.log( 'getCidadesOfUfJson().' );

    return cidadesJson;
  }
}
